import React, { useRef } from 'react';
import styles from './WHWifiPackages.module.scss'
import OfferCard from '../../../components/OfferCard/OfferCard';
import OfferCardsContainer from '../../../components/OfferCardsContainer/OfferCardsContainer';
import Button from '../../../components/Button/Button';

const WHWifiPackages = (): JSX.Element => {
  const ref1 = useRef<HTMLDivElement | null>(null);
  const ref2 = useRef<HTMLDivElement | null>(null);
  const ref3 = useRef<HTMLDivElement | null>(null);

  return (
    <section className={`grid-12 ${styles.container}`}>
      <div className={styles.inner}>

        <h2 className={`headline__h2 color--dark-blue ${styles.head}`}>
          Fiber is built for the internet.
        </h2>

        <h3 className={`headline__h4 color--dark-blue ${styles.subhead}`}>
          Built for speed. Built for service. Built for the future. Built for you.
        </h3>

        <OfferCardsContainer>
          <OfferCard
            ref={ref1}
            headline='Connect & Control'
            subhead='1 Gig'
            price='$80'
            borderColor='ui-grey-4'
            reveal={true}
            onWhite={true}
          >
            <p>
              The perfect plan for reliable connectivity at our lowest price!
            </p>

            <ul>
              <li>1,000 MBPS (that&apos;s 1 Gig)</li>
              <li>1 gigabit upload & download</li>
              <li>Includes a WiFi6 router</li>
            </ul>
          </OfferCard>

          <OfferCard
            ref={ref2}
            headline='Work Hard, Play Hard'
            subhead='2 Gigs'
            price='$95'
            borderColor='ui-grey-4'
            reveal={false}
            onWhite={true}
          >
            <p>
              The connection developed for remote workers & gamers who expect the best!
            </p>

            <ul>
              <li>2,000 MBPS (that&rsquo;s 2 Gigs)</li>
              <li>2 gigabit upload & download</li>
              <li>Includes 1 WiFi Extender</li>
              <li>Includes a WiFi6 router</li>
            </ul>
          </OfferCard>

          <OfferCard
            ref={ref3}
            headline='Everything & Everywhere'
            subhead='5 Gigs'
            price='$195'
            borderColor='ui-grey-4'
            reveal={false}
            onWhite={true}
          >
            <p>
              This is the ultimate connection for those who want to cover it all & won&apos;t settle for less!
            </p>

            <ul>
              <li>5,000 MBPS (that&rsquo;s 5 Gigs)</li>
              <li>5 gigabit upload & download</li>
              <li>Includes a WiFi6 router</li>
              <li>Includes up to 2 WiFi extender devices for WholeHome coverage – even outside!</li>
            </ul>
          </OfferCard>
        </OfferCardsContainer>

        <div className={styles.otherPackages}>
          <div>
            <p>
              <span>Looking for streaming TV packages?</span><br />
              We can help with that.
            </p>
            <Button
              size='sm'
              href='http://streaming.citysidefiber.com/start'
              attr={{rel: 'noreferrer'}}
              target='_blank'
              >
              Learn more
            </Button>
          </div>

          <div>
            <p>
              <span>Interested in a low latency,</span><br />
              10Gig+ direct fiber install?
            </p>
            <Button size='sm' href='mailto: hello@citysidefiber.com'>hello@citysidefiber.com</Button>
          </div>
        </div>
      </div>
    </section>
  )
};

export default WHWifiPackages;